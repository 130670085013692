@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    height: 100vh;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @media print {
    html, body {
      height: auto;
      margin: 0 !important;
      padding: 0 !important;
      overflow: hidden;
    }
  }

  html {
    font-family: sans-serif; /* Reset font-family if needed */
  }
  
  .normal-case {
    text-transform: none !important;
  }


  